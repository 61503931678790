import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ds-specializeddistribution',
  templateUrl: './ds-specializeddistribution.component.html',
  styleUrls: ['./ds-specializeddistribution.component.css']
})
export class DsSpecializeddistributionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Isspecial');
  }

}
