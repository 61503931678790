
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ApiIntegService } from '../api-integ.service';

@Component({
  selector: 'app-planpricing',
  templateUrl: './planpricing.component.html',
  styleUrls: ['./planpricing.component.css']
})
export class PlanpricingComponent implements OnInit {
  StdNdCorData: any;
    TotalCost: number;
    LiceneData: any;
    StorageData: string;
    resultPrecartData: any;
    PrecartItem: any;
    item1: any;
 
  constructor(private router: Router, private formBuilder: FormBuilder, private ApiServices: ApiIntegService) { }
  loader: boolean = true;
  ngOnInit() {

    debugger;
    window.scroll(0, 0);
    this.getCustLicenceName();
    this.getItemName();
    this.getStandardandCorporateSolution();
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
    }, 700);

  }

  public getStandardandCorporateSolution() {

    this.ApiServices.getStandardandCorporateSolution().subscribe((data) => {
      if (!data.isError) {
        debugger;
        this.StdNdCorData = data.data;
        this.StdNdCorData.forEach(function (element) {

          element.PosName = "POS";
          element.QTY = 0;

        })
        this.StdNdCorData[0].PosName = "Pos";
        this.StdNdCorData[0].item_ShortName = "Module";
        this.StdNdCorData[1].PosName = "express";
        this.StdNdCorData[2].PosName = "corporate";
        this.StdNdCorData[3].PosName = "cloud";
        this.StdNdCorData[4].PosName = "omni";
        this.StdNdCorData[5].PosName = "HOSERVER";
        //this.StdNdCorData[5].PosName = "HOSERVER";
        //this.StdNdCorData[6].PosName = "Branchserver";
        //this.StdNdCorData[7].PosName = "AdditionalClient";
      }
    })
  }
  myOptions =
    {
      'placement': 'top',
      'show-delay': 500,
      'theme': 'light',
      'shadow': 'true'
    }
  ItemData: any;
  public getItemName() {

    this.ApiServices.GetItemName().subscribe((data) => {
      if (!data.isError) {
        debugger;
        this.ItemData = data.data;
      }

    })
  }
  public getCustLicenceName() {
    debugger;

    this.TotalCost = 0;
    this.ApiServices.getCustLicenceName().subscribe((data) => {
      this.LiceneData = data.data;
      this.LiceneData.forEach(function (element) {
        element.subcategory = false;
        element.chldDtls = JSON.parse(element.chldDtls);

      })
      this.StorageData = localStorage.getItem('CartCRM');
      if (this.StorageData != null) {
        this.resultPrecartData = JSON.parse(this.StorageData);
        if (this.resultPrecartData.length != 0) {
          this.PrecartItem = this.resultPrecartData.length;
          for (var i = 0; i < this.resultPrecartData.length; i++) {
            for (var j = 0; j < this.LiceneData.length; j++) {
              this.LiceneData[j].chldDtls.forEach((element, index) => {
                if (this.resultPrecartData[i].CustLincid == element.CustLincId) {

                  element.TempPosEdi = this.resultPrecartData[i].TempPosEdi;
                  element.TempExpEdi = this.resultPrecartData[i].TempExpEdi;
                  element.TempCorEdi = this.resultPrecartData[i].TempCorEdi;
                  element.TempCldEdi = this.resultPrecartData[i].TempCldEdi;
                  if (element.TempPosEdi == true) {
                    this.TotalCost += element.CustLincPOSPrice;
                  }
                  else if (element.TempExpEdi == true) {
                    this.TotalCost += element.CustLincExpPrice;
                  }
                  else if (element.TempCorEdi == true) {
                    this.TotalCost += element.CustLincCorPrice;
                  }
                  else if (element.TempCldEdi == true) {
                    this.TotalCost += element.CustLincCldPrice;
                  }
                }



              });
            }
          }
        }

      }

      console.log(this.LiceneData);

    })
  }
  OpenChildData(item, index) {
    this.item1 = item.subcategory;
    this.LiceneData.forEach(function (element) {
      element.subcategory = false;

    })
    if (this.item1 == false) {
      this.LiceneData[index].subcategory = true;
    }
    else {
      this.LiceneData[index].subcategory = false;
    }

  }
}
