import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-barsnbreweries',
  templateUrl: './barsnbreweries.component.html',
  styleUrls: ['./barsnbreweries.component.css']
})
export class BarsnbreweriesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  Requestdemo() {
    debugger;
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Isbars');
  }

}
