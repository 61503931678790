import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiIntegService } from '../api-integ.service';

@Component({
  selector: 'app-systemrequirement',
  templateUrl: './systemrequirement.component.html',
  styleUrls: ['./systemrequirement.component.css']
})
export class SystemrequirementComponent implements OnInit {

  SysRequireForm: FormGroup;
  loader: boolean = true;
  constructor(private router: Router, private formBuilder: FormBuilder, private SystemRequirement: ApiIntegService) { }


  serverinfo: boolean;
  clientinfo: boolean;
  centralinfo: boolean;

  ngOnInit() {

    window.scroll(0, 0);
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
    }, 700)

    this.SysRequireForm = this.formBuilder.group({
      //LeadId: [0],
      BusinessMode: [],
      BillingCounter: [],
      AvgTransaction: [],

    });
  }
  sysReq_ServerRAM: string = '';
  sysReq_ServerLAN: string = '';
  sysReq_ServerProcessor: string = '';
  sysReq_ServerCloud: string = '';
  sysReq_ServerHardDisk: string = '';
  sysReq_ServerDatabaseName: string = '';
  sysReq_ServerOS: string = '';

  sysReq_ClientRAM: string = '';
  sysReq_ClientHardDisk: string = '';
  sysReq_ClientOS: string = '';
  sysReq_ClientProcessor: string = '';
  sysReq_ClientDatabase: string = '';
  sysReq_ClientLAN: string = '';

  sysReq_PosRAM: string = '';
  sysReq_PosOS: string = '';
  sysReq_PosProcessor: string = '';
  sysReq_PosMonitor: string = '';
  sysReq_PosScreen: string = '';
  sysReq_CentralOS: string = '';
  sysReq_CentralHardware: string = '';
  sysReq_CentralCloudPlan: string = '';


  public Getfilterdata(event) {
    debugger;
    this.SystemRequirement.GetSystemRequirement(this.SysRequireForm.value.BusinessMode, this.SysRequireForm.value.BillingCounter, this.SysRequireForm.value.AvgTransaction).subscribe(Response => {
      if (Response.data) {
        console.log(Response.data);

        if (Response.data.sysReq_ServerRAM == "" || Response.data.sysReq_ServerRAM == null) {
          this.serverinfo = false;
        }
        else {
          this.serverinfo = true;
        }


        if (Response.data.sysReq_ClientRAM == "0" || Response.data.sysReq_ClientRAM == null) {
          this.clientinfo = false;
        }
        else {
          this.clientinfo = true;
        }

        if (Response.data.sysReq_CentralOS == "0" || Response.data.sysReq_CentralOS == null) {
          this.centralinfo = false;
        }
        else {
          this.centralinfo = true;
        }

        this.sysReq_ServerRAM = Response.data.sysReq_ServerRAM;
        this.sysReq_ServerLAN = Response.data.sysReq_ServerLAN;
        this.sysReq_ServerProcessor = Response.data.sysReq_ServerProcessor;
        this.sysReq_ServerCloud = Response.data.sysReq_ServerCloud;
        this.sysReq_ServerHardDisk = Response.data.sysReq_ServerHardDisk;
        this.sysReq_ServerDatabaseName = Response.data.sysReq_ServerDatabaseName;
        this.sysReq_ServerOS = Response.data.sysReq_ServerOS;

        this.sysReq_ClientRAM = Response.data.sysReq_ClientRAM;
        this.sysReq_ClientHardDisk = Response.data.sysReq_ClientHardDisk;
        this.sysReq_ClientOS = Response.data.sysReq_ClientOS;
        this.sysReq_ClientProcessor = Response.data.sysReq_ClientProcessor;
        this.sysReq_ClientDatabase = Response.data.sysReq_ClientDatabase;
        this.sysReq_ClientLAN = Response.data.sysReq_ClientLAN;

        this.sysReq_PosRAM = Response.data.sysReq_PosRAM;
        this.sysReq_PosOS = Response.data.sysReq_PosOS;
        this.sysReq_PosProcessor = Response.data.sysReq_PosProcessor;
        this.sysReq_PosMonitor = Response.data.sysReq_PosMonitor;
        this.sysReq_PosScreen = Response.data.sysReq_PosScreen;

        this.sysReq_CentralOS = Response.data.sysReq_CentralOS;
        this.sysReq_CentralHardware = Response.data.sysReq_CentralHardware;
        this.sysReq_CentralCloudPlan = Response.data.sysReq_CentralCloudPlan;


      }
    });

  }


}
