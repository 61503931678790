import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rt-sportinggoods',
  templateUrl: './rt-sportinggoods.component.html',
  styleUrls: ['./rt-sportinggoods.component.css']
})
export class RtSportinggoodsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Issports');
  }

}
