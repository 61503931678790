import { Injectable } from '@angular/core';
import { Globalcommonclass } from 'src/app/Common/globalcommonclass';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { Precartinfomodel } from './Model/precartinfomodel';
import { CartModel } from './Model/cart-model';

@Injectable({
  providedIn: 'root'
})

export class ApiIntegService {
  baseApiUrl = Globalcommonclass.BASE_API_URL;

  invokeLoaderEnvet = new EventEmitter();
  LoaderEnvet(obj) {
    this.invokeLoaderEnvet.emit();
  }
  
  constructor(private http: HttpClient) {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.info(error); // log to console instead
      return of(result as T);
    };
  }
  public GetBusinessVerticalList() {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'NewLead/GetBusinessVerticalList').pipe(
      tap((res) => console.log(res)),
      catchError(this.handleError<any>('GetBusinessVerticalList'))
    );
  }

  NewLeadAddOrUpdate(data: any) {
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'NewLead/InsertOrUpdate', data).pipe(
      catchError(this.handleError<any>('NewLeadAddOrUpdate'))
    );
  }

  QuotationReqInset(data: any) {
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'Quotation/InsertOrUpdateQuotationReq', data).pipe(
      catchError(this.handleError<any>('InsertOrUpdateQuotationReq'))
    );
  }

  public GenerateOTPforNewUser(ContactNo: string) {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'NewLead/GenerateOTPforNewUser/' + ContactNo).pipe(
      catchError(this.handleError<any>('GenerateOTPforNewUser'))
    );
  }

  public GenerateOTPforLogin(ContactNo: string) {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'NewLead/GenerateOTPforLogin/' + ContactNo).pipe(
      catchError(this.handleError<any>('GenerateOTPforLogin'))
    );
  }
  public GetDataThroughPincode(PinCode: number) {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'LookUp/GetPincodeInfo/' + PinCode).pipe(
      catchError(this.handleError<any>('GetDataThroughPincode'))
    );
  }
  public getCustLicenceName() {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'CustLice/getCustLicenceName').pipe(
      tap((res) => console.log(res)),
      catchError(this.handleError<any>('getCustLicenceName'))
    );
  }
  public GetItemName() {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'Product/GetItemName').pipe(
      tap((res) => console.log(res)),
      catchError(this.handleError<any>('GetItemName'))
    );
  }
  public getStandardandCorporateSolution() {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'Product/getStandardandCorporateSolution').pipe(
      tap((res) => console.log(res)),
      catchError(this.handleError<any>('getStandardandCorporateSolution'))
    );
  }

  public Login(data: any) {
    debugger;
    var obj =
    {
      "MobileNo": data.ContactNo,
      "NewLead_OTP": data.NewLead_OTP.toString()
    }
    return this.http.post<any>(this.baseApiUrl + 'NewLead/LoginNewLead', obj).pipe(
      catchError(this.handleError<any>('Login'))
    );
  }

  public GetSingleCustLicense(CustLiscId) {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'CustLice/GetSingleCustLicense/' + CustLiscId).pipe(
      tap((res) => console.log(res)),
      catchError(this.handleError<any>('GetSingleCustLicense'))
    );
  }

  AddPreSingleCart(Precart: Precartinfomodel) {
    return this.http.post<any>(this.baseApiUrl + 'Cart/InsertUpdatePreCart', Precart).pipe(
      catchError(this.handleError<any>('InsertUpdatePreCart'))
    );
  }
  AddPreTempSingleCart(Precart: Precartinfomodel) {
    return this.http.post<any>(this.baseApiUrl + 'Cart/InsertUpdateTempPreCart', Precart).pipe(
      catchError(this.handleError<any>('InsertUpdateTempPreCart'))
    );
  }
  public AddPreCarts(Data: any) {
    debugger;
    return this.http.post<Precartinfomodel>(this.baseApiUrl + 'Cart/AddPreCarts', Data).pipe(
      catchError(this.handleError<any>('AddPreCarts'))
    );
  }

  CreateOrder(obj) {
    return this.http.post<any>(this.baseApiUrl + 'PaymentGateway/CreateOrder', obj).pipe(
      catchError(this.handleError<any>('CreateOrder'))
    );

  }

  CheckPaymentStatus(obj) {
    return this.http.post<any>(this.baseApiUrl + 'PaymentGateway/CheckStatus', obj).pipe(
      catchError(this.handleError<any>('CheckStatus'))
    );

  }
  public AddCartBuyDetails(Data: any) {
    debugger;
    return this.http.post<CartModel>(this.baseApiUrl + 'Cart/AddCart', Data).pipe(
      catchError(this.handleError<any>('AddCart'))
    );
  }
  public AddCartBuyDetailsEdition(Data: any) {
    debugger;
    return this.http.post<CartModel>(this.baseApiUrl + 'Cart/AddCartEdition', Data).pipe(
      catchError(this.handleError<any>('AddCart'))
    );
  }

  DeleteChangeEditionPreCart(Precart: Precartinfomodel) {
    return this.http.post<any>(this.baseApiUrl + 'Cart/DeleteChangeEditionPreCart', Precart).pipe(
      catchError(this.handleError<any>('DeleteChangeEditionPreCart'))
    );
  }

  ClearPreCartItem(Precart: Precartinfomodel) {
    return this.http.post<any>(this.baseApiUrl + 'Cart/ClearPreCartItem', Precart).pipe(
      catchError(this.handleError<any>('ClearPreCartItem'))
    );
  }

  public GetSystemRequirement(BusinessMode: any, BillingCounter: any, AvgTransaction: any) {
    debugger;
    var obj =
    {
      "SysReq_BusinessMode": BusinessMode,
      "SysReq_Num_of_BillingCounter": BillingCounter,
      "SysReq_AvgTransaction": AvgTransaction
    }
    return this.http.post<any>(this.baseApiUrl + 'SystemRequirement/GetSystemRequirement', obj).pipe(
      catchError(this.handleError<any>('GetSystemRequirement'))

    );
  }
  InsertOrUpdateUserRegisteration(data: any) {
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'UserRegistration/InsertOrUpdateUserRegisteration', data).pipe(
      catchError(this.handleError<any>('InsertOrUpdateUserRegisteration'))
    );
  }


}
