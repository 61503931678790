import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fb-meatnfrozen',
  templateUrl: './fb-meatnfrozen.component.html',
  styleUrls: ['./fb-meatnfrozen.component.css']
})
export class FbMeatnfrozenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Ismeat');
  }
}
