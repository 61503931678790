import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supportpricelist',
  templateUrl: './supportpricelist.component.html',
  styleUrls: ['./supportpricelist.component.css']
})
export class SupportpricelistComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
  }

}
