import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ds-lubricants',
  templateUrl: './ds-lubricants.component.html',
  styleUrls: ['./ds-lubricants.component.css']
})
export class DsLubricantsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Islubricant');
  }
}
