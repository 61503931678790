import { Component, OnInit } from '@angular/core';
import { RequestdemoService } from 'src/app/requestdemo/requestdemo.service'
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globalcommonclass } from '../Common/globalcommonclass';
declare var _$;
declare var $: any;


@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  subscription: Subscription;
  constructor(private formBuilder: FormBuilder, private RequestdemoService: RequestdemoService, private router: Router) { }

  Contactform: FormGroup;

  ngOnInit() {
    window.scrollTo(0, 0);
    this.Contactform = this.formBuilder.group({
      Name: [],
      EmailId: [],
      ContactNo: [],
      CompanyName: [],
      Subject: [],
      Message: []
    });

    //GetCustomerfromweb
  }
  submit() {
    debugger;
    var data = {
      "Name": this.Contactform.value.Name,
      "EmailId": this.Contactform.value.EmailId,
      "ContactNo": (this.Contactform.value.ContactNo).toString(),
      "CompanyName": this.Contactform.value.CompanyName,
      "Subject": this.Contactform.value.Subject,
      "Message": this.Contactform.value.Message
    }
    this.RequestdemoService.GetCustomerfromweb(data).subscribe(Response => {
      if (Response.isError == false) {
        alert("Thank you for visiting us we will contact you soon !! ");
      }
      else {
        alert("Something went wrong please try again ")
      }
    });
  }


}
