import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-licenceaggrement',
  templateUrl: './licenceaggrement.component.html',
  styleUrls: ['./licenceaggrement.component.css']
})
export class LicenceaggrementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
  }

}
