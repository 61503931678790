import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rt-gro-supermarket',
  templateUrl: './rt-gro-supermarket.component.html',
  styleUrls: ['./rt-gro-supermarket.component.css']
})
export class RtGroSupermarketComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  Requestdemo() {
    debugger;
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Issupermarket');
  }

}
