import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ds-pachagefood',
  templateUrl: './ds-pachagefood.component.html',
  styleUrls: ['./ds-pachagefood.component.css']
})
export class DsPachagefoodComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Ispackage');
  }

}
