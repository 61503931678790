import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-entitlement',
  templateUrl: './support-entitlement.component.html',
  styleUrls: ['./support-entitlement.component.css']
})
export class SupportEntitlementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
  }

}
