import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { RetailComponent } from './retail/retail.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { RtAccessoriesComponent } from './rt-accessories/rt-accessories.component';
import { RtElectronicsComponent } from './rt-electronics/rt-electronics.component';
import { RtGroSupermarketComponent } from './rt-gro-supermarket/rt-gro-supermarket.component';
import { RtFashionfootwareComponent } from './rt-fashionfootware/rt-fashionfootware.component';
import { RtFurniturehomeComponent } from './rt-furniturehome/rt-furniturehome.component';
import { RtBeautyncareComponent } from './rt-beautyncare/rt-beautyncare.component';
import { RtFruitnveggiesComponent } from './rt-fruitnveggies/rt-fruitnveggies.component';
import { RtApperatntextileComponent } from './rt-apperatntextile/rt-apperatntextile.component';
import { RtGlassncrockeriesComponent } from './rt-glassncrockeries/rt-glassncrockeries.component';
import { RtSportinggoodsComponent } from './rt-sportinggoods/rt-sportinggoods.component';
import { FbBakerynconfeComponent } from './fb-bakerynconfe/fb-bakerynconfe.component';
import { FbQuickrestComponent } from './fb-quickrest/fb-quickrest.component';
import { FbMeatnfrozenComponent } from './fb-meatnfrozen/fb-meatnfrozen.component';
import { FbDelivertakewayComponent } from './fb-delivertakeway/fb-delivertakeway.component';
import { ContactusComponent } from './contactus/contactus.component';
import { PartnerComponent } from './partner/partner.component';
import { BarsnbreweriesComponent } from './barsnbreweries/barsnbreweries.component';
import { DsPachagefoodComponent } from './ds-pachagefood/ds-pachagefood.component';
import { DsSpecializeddistributionComponent } from './ds-specializeddistribution/ds-specializeddistribution.component';
import { DsLubricantsComponent } from './ds-lubricants/ds-lubricants.component';
import { RequestdemoComponent } from './requestdemo/requestdemo.component';
import { TermoserviceComponent } from './termoservice/termoservice.component';
import { LicenceaggrementComponent } from './licenceaggrement/licenceaggrement.component';
import { ComplainprocedureComponent } from './complainprocedure/complainprocedure.component';
import { SupportaggrementComponent } from './supportaggrement/supportaggrement.component';
import { SystemrequirementComponent } from './systemrequirement/systemrequirement.component';
import { PlanpricingComponent } from './planpricing/planpricing.component';
import { SupportpricelistComponent } from './supportpricelist/supportpricelist.component';
import { SupportEntitlementComponent } from './support-entitlement/support-entitlement.component';
import { ProductDeliveryComponent } from './product-delivery/product-delivery.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';



@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    HomeComponent,
    FooterComponent,
    RetailComponent,
    PrivacypolicyComponent,
    RtAccessoriesComponent,
    RtElectronicsComponent,
    RtGroSupermarketComponent,
    RtFashionfootwareComponent,
    RtFurniturehomeComponent,
    RtBeautyncareComponent,
    RtFruitnveggiesComponent,
    RtApperatntextileComponent,
    RtGlassncrockeriesComponent,
    RtSportinggoodsComponent,
    FbBakerynconfeComponent,
    FbQuickrestComponent,
    FbMeatnfrozenComponent,
    FbDelivertakewayComponent,
    ContactusComponent,
    PartnerComponent,
    BarsnbreweriesComponent,
    DsPachagefoodComponent,
    DsSpecializeddistributionComponent,
    DsLubricantsComponent,
    RequestdemoComponent,
    TermoserviceComponent,
    LicenceaggrementComponent,
    ComplainprocedureComponent,
    SupportaggrementComponent,
    SystemrequirementComponent,
    PlanpricingComponent,
    SupportpricelistComponent,
    SupportEntitlementComponent,
    ProductDeliveryComponent,
    RefundPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

  ],
  providers: [FormsModule,
    Title  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
