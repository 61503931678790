import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fb-quickrest',
  templateUrl: './fb-quickrest.component.html',
  styleUrls: ['./fb-quickrest.component.css']
})
export class FbQuickrestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Isquickrestro');
  }

}
