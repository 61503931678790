import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fb-bakerynconfe',
  templateUrl: './fb-bakerynconfe.component.html',
  styleUrls: ['./fb-bakerynconfe.component.css']
})
export class FbBakerynconfeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Isbakery');
  }

}
