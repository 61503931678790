import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termoservice',
  templateUrl: './termoservice.component.html',
  styleUrls: ['./termoservice.component.css']
})
export class TermoserviceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
  }

}
