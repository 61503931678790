import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rt-fruitnveggies',
  templateUrl: './rt-fruitnveggies.component.html',
  styleUrls: ['./rt-fruitnveggies.component.css']
})
export class RtFruitnveggiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Isfruit');
  }

}
