export class NewLeadDetails {
  LeadDetailsId: number;
  CreatedBy: number;
  ModifiedBy: number;
  LeadId: number;
  CustName: string;
  Cust_Designation: string;
  ContactType: string;
  ContactNo: string;
  DateTime: Date;
  ModifiedDate: Date;
  IsDeleted: boolean;
  IsDefault: boolean;
  ContactDetails: string;
}
