
import { Injectable, EventEmitter } from '@angular/core';
import { Globalcommonclass } from 'src/app/Common/globalcommonclass';
import { of, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Accessmodel } from '../Model/accessmodel';

@Injectable({
  providedIn: 'root'
})
export class RequestdemoService {
  invokeRefreshEnvet = new EventEmitter();
  subsRefreshCartVar: Subscription;
  RefreshEnvet() {
    debugger;

    //location.reload();
    this.invokeRefreshEnvet.emit();
  }

  baseApiUrl = Globalcommonclass.BASE_API_URL;

  constructor(private http: HttpClient) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.info(error); // log to console instead
      return of(result as T);
    };
  }

  NewLeadAddOrUpdate(data: any) {
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'NewLead/InsertOrUpdate', data).pipe(
      catchError(this.handleError<any>('NewLeadAddOrUpdate'))
    );
  }

  public GetDataThroughPincode(PinCode: number) {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'LookUp/GetPincodeInfo/' + PinCode).pipe(
      catchError(this.handleError<any>('GetDataThroughPincode'))
    );
  }

  public GetDataThroughUserId(data) {
    return this.http.post<any>(this.baseApiUrl + 'NewLead/GetNewLeadSearch', data).pipe(
      catchError(this.handleError<any>('GetDataThroughUserId'))
    );
  }


  public GetLeadDetailById(Id) {
    return this.http.get<any>(this.baseApiUrl + 'NewLead/GetLeadByID/' + Id).pipe(
      catchError(this.handleError<any>('GetLeadDetailById'))
    );
  }


  public GetleadSearchGridView(accessModel: Accessmodel) {
    debugger;
    return this.http.post<any>(this.baseApiUrl + 'NewLead/GetLeadSearchGridview', accessModel).pipe(
      catchError(this.handleError<any>('GetLeadDetailById'))
    );
  }
  public GetAllleadDetailsByLeadId(Id) {
    return this.http.get<any>(this.baseApiUrl + 'NewLead/GetleadDetailsByLeadId/' + Id).pipe(
      catchError(this.handleError<any>('GetAllleadDetailsByLeadId'))
    );
  }

  public GetRegisterationName(Id) {
    return this.http.get<any>(this.baseApiUrl + 'UserRegistration/GetRegisterationName/' + Id).pipe(
      catchError(this.handleError<any>('GetRegisterationName'))
    );
  }

  public GetleadHistoryByLeadId(Id) {
    return this.http.get<any>(this.baseApiUrl + 'NewLead/GetleadHistoryByLeadId/' + Id).pipe(
      catchError(this.handleError<any>('GetleadHistoryByLeadId'))
    );
  }


  public GetticketHistoryByLeadId(Id) {
    return this.http.get<any>(this.baseApiUrl + 'Ticket/GetticketHistoryByLeadId/' + Id).pipe(
      catchError(this.handleError<any>('GetticketHistoryByLeadId'))
    );
  }
  public GetTicketByTicketID(Id) {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'Ticket/GetTicketByTicketID/' + Id).pipe(
      catchError(this.handleError<any>('GetTicketByTicketID'))
    );
  }

  public GetTicketReplyByTicketID(Id) {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'Ticket/GetTicketReplyByTicketID/' + Id).pipe(
      catchError(this.handleError<any>('GetTicketReplyByTicketID'))
    );
  }

  public GetBusinessVerticalList() {
    debugger;
    return this.http.get<any>(this.baseApiUrl + 'NewLead/GetBusinessVerticalList').pipe(
      tap((res) => console.log(res)),
      catchError(this.handleError<any>('GetBusinessVerticalList'))
    );
  }
  public GetCustomerfromweb(data) {

    return this.http.post<any>(this.baseApiUrl + 'NewLead/GetCustomerfromweb', data).pipe(
      tap((res) => console.log(res)),
      catchError(this.handleError<any>('GetCustomerfromweb'))
    );
  }

}
