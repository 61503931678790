export class Globalcommonclass {
  public static BASE_API_URL: string = 'http://down.retailsoft.in:6565/api/';
//  public static BASE_API_URL: string = 'http://localhost:65268/api/';
  public static GlobalCompanyID: number = 1;
  public static GlobalBranchID: number = 1;
  public static GlobalPOSID: number = 1;
  public static GlobalFinancialYearID: number = 1;
  public static GlobalUserID: number = 1;
  public static GlobalBookTypeID: number = 1;
  public static GetVerticalID: number = 1;
  public static GlobalSystemDate: Date = new Date();
}
