import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rt-electronics',
  templateUrl: './rt-electronics.component.html',
  styleUrls: ['./rt-electronics.component.css']
})
export class RtElectronicsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Iselectronics');
  }

}
