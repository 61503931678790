import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiIntegService } from '../api-integ.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {
  PartnerForm: FormGroup;
    DMLMode: string;
    Pincodelength: any;

  constructor(private formBuilder: FormBuilder, private ApiService: ApiIntegService, private router: Router) { }

  IsName: boolean = false;
  IsContactNo: boolean = false;
  IsPincode: boolean = false;
  IsEmail: boolean = false;
  IsBusinessName: boolean = false;
  ngOnInit() {
    window.scrollTo(0, 0);
    this.PartnerForm = this.formBuilder.group({
      Pincode: ['', Validators.required],
      Name: ['', Validators.required],
      ContactNo: ['', [Validators.maxLength(10), Validators.minLength(10)]],
      BusinessName: ['', Validators.required],
      Email: ['', Validators.required],
      Area: [],
      Taluka: [],
      District: [],
      State: [],
      Country: [],
      Zone: [],
      Division: [],
     
      
    
    })

    this.PartnerForm.reset();
  }

  Pincode() {
    debugger;
    if (this.PartnerForm.value.Pincode.errors == null) {
      this.ApiService.GetDataThroughPincode(this.PartnerForm.value.Pincode).subscribe(response => {
        debugger;
        if (response) {
          if (!response.IsError) {
            this.PartnerForm.controls.Area.setValue(response.data.area);
            this.PartnerForm.controls.Taluka.setValue(response.data.taluka);
            this.PartnerForm.controls.District.setValue(response.data.districtName);
            this.PartnerForm.controls.Division.setValue(response.data.stateDivisionName);
            this.PartnerForm.controls.State.setValue(response.data.stateName);
            this.PartnerForm.controls.Country.setValue(response.data.country);
            this.PartnerForm.controls.Zone.setValue(response.data.countryName);
          }
          else {
            this.PartnerForm.controls.Area.setValue("");
            this.PartnerForm.controls.Taluka.setValue("");
            this.PartnerForm.controls.District.setValue("");
            this.PartnerForm.controls.Division.setValue("");
            this.PartnerForm.controls.State.setValue("");
            this.PartnerForm.controls.Country.setValue("");
          }
        }
      });
    }
    else {
      this.PartnerForm.controls.Customershipping_State.setValue("");
    }

  }
  keyDownFunction(event) {
    if (event.keyCode == 38) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input, select"))
      const index =
        (inputs.indexOf(document.activeElement) - 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }
    else if (event.keyCode == 13 || event.keyCode == 40) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input, select"))
      const index =
        (inputs.indexOf(document.activeElement) + 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()


    }

  }
  public register()
  {

    debugger;
    if (this.PartnerForm.value.Name == "") {
      this.IsName = true;
      return;
    }
    if (this.PartnerForm.value.BusinessName == "") {
      this.IsBusinessName = true;
      return;
    }
    let RegPattern = new RegExp("^((\\+91-?)|0)?[0-9]{10}$");
    if (!RegPattern.test(this.PartnerForm.value.ContactNo)) {
      this.IsContactNo = true;
      return;
    }

    var EMAIL_REGEXP = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    if (!EMAIL_REGEXP.test(this.PartnerForm.value.Email)) {
      this.IsEmail = true
      return;
    }
    var x = this.PartnerForm.value.Pincode
    
    if (x.toString().length != 6) {
      this.IsPincode = true
      return;
    }
    var data = {
      "UserId": 0,
      "Name": this.PartnerForm.value.Name,
      "Email": this.PartnerForm.value.Email,
      "Mobile": (this.PartnerForm.value.ContactNo).toString(),
      "AddressOne": "",
      "AddressTwo": "",
      "DateOfBirth": "",
      "Pancard": "",
      "AdharNo":"",
      "PassPort": "",
      "pfNo": "",
      "Pincode": parseInt(this.PartnerForm.value.Pincode),
      "Area": this.PartnerForm.value.Area,
      "Taluka": this.PartnerForm.value.Taluka,
      "District": this.PartnerForm.value.District,
      "StateZone": this.PartnerForm.value.Zone,
      "Division": this.PartnerForm.value.Division,
      "State": this.PartnerForm.value.State,
      "CountryZone": "",
      "Country": this.PartnerForm.value.Country,
      "Role": 3,
      "Designation": "Partner",
      "Team": "Partner Team",
      "ApprovalLevel": "Partner Level Approval",
      "Status": "Active",
      "Username": this.PartnerForm.value.Email,
      "Password": (this.PartnerForm.value.ContactNo).toString(),
      "NewLead": true,
      "GSTMaster": true,
      "CategoryMaster": true,
      "ProductMaster": true,
      "PartnerMaster": true,
      "UserRegistration": true,
      "Followup": true,
      "QuotationEntry": true,
      "SalesEntry": true,
      "PaymentEntry": true,
      "PaymentApproval": true,
      "OrderApproval": true,
      "LicenseApproval": true,
      "AuthorityPage": true,
      "SupportUpdates": true,
      "CreatedDate": new Date(),
      "CreatedBy": 1,
      "ModifiedBy": 1,
      "ModifiedDate": new Date(),
      "IsDeleted": false,
      "LeadsTransfer": true,
      "Targets": true,
      "DeviceId": '',
      "DeviceType": "",
      "PartnerMargin": 0,
      "CompanyName": this.PartnerForm.value.BusinessName,
      "GSTNo": "",
      "RegistationType": "",
      "SaleType": "",
      "PartnerType_Id": 0,
      "PartnerType": "Refferal Partner",
      "SalesContactNo": "",
      "SupportContactNo": "",
      "UserProfile": "",
      "BranchId": 0,
      "User_PartnerBranchId": 0,
      "ParentPartnerId": 1
    }
    var a = JSON.stringify(data);
    console.log(a);
    this.ApiService.InsertOrUpdateUserRegisteration(data).subscribe(response => {
      debugger;
      if (!response.isError) {
        alert("Welcome To RetailSoft Pvt Ltd.Your CRM Potal login is UserId: " + this.PartnerForm.value.Email + " Password: " + this.PartnerForm.value.ContactNo );
        this.PartnerForm.reset();
        this.DMLMode == "Insert"
      }
      else {
        alert(response.message);
      }
    });

  }




}
