
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { RequestdemoService } from 'src/app/requestdemo/requestdemo.service'
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Globalcommonclass } from '../Common/globalcommonclass';
import { NewLeadDetails } from '../Model/new-lead-details';
export let browserRefresh = false;
declare var _$;
declare var $: any;

@Component({
  selector: 'app-requestdemo',
  templateUrl: './requestdemo.component.html',
  styleUrls: ['./requestdemo.component.css'],
   encapsulation: ViewEncapsulation.None
})
export class RequestdemoComponent implements OnInit {
  subscription: Subscription;
  UserParentId: number;
  IsEditPriviledge: boolean = false;
  PartnerLead: string = 'No';
  RoleId: number;
  IsSourceName: boolean = false;
  AssignToId: any;

  constructor(private formBuilder: FormBuilder, private RequestdemoService: RequestdemoService, private router: Router,) {
}

  columnList: any = [
    {
      header: 'Sr No.', dataKey: "${index}", isEditable: false, type: 'text', "cellTemplate": "",
      "cellEditTemplate": "", isVisible: true, htmlAttrs: [{ "name": 'style', "value": "width:5px" }]
    },

    {
      header: 'Name', dataKey: 'CustName', isEditable: true, "type": "number", "cellTemplate": "",
      "cellEditTemplate": "", htmlAttrs: [{ "name": 'style', "value": "width:80px" }]
    },

    //{
    //    header: 'Contact Type', dataKey: 'ContactType', isEditable: true, "type": "number", "cellTemplate": "",
    //    "cellEditTemplate": "", htmlAttrs: [{ "name": 'style', "value": "width:230px" }]
    //},

    {
      header: 'Designation', dataKey: 'Cust_Designation', isEditable: true, "type": "number", "cellTemplate": "",
      "cellEditTemplate": "", htmlAttrs: [{ "name": 'style', "value": "width:30px" }]
    },

    {
      header: 'Contact', dataKey: 'ContactNo', isEditable: true, "type": "number", "cellTemplate": "",
      "cellEditTemplate": "", htmlAttrs: [{ "name": 'style', "value": "width:80px" }]
    },
    //{
    //       header: 'Contact Type', dataKey: 'ContactType', isEditable: true, "type": "number", "cellTemplate": "",
    //       "cellEditTemplate": "", htmlAttrs: [{ "name": 'style', "value": "width:230px" }]
    //   },

  ];
  ngOnDestroy() {
    debugger;
    location.reload();
  }
  ngAfterViewInit() {
    //location.reload();
    this.initGrid();
  }

  DMLMode = "Insert";
  LeadForm: FormGroup;
  leadMobForm: FormGroup;
  UserId: number = 0;

  ContactType: string;
  businessverticals: any;
  public highlightedItem: any = {};
  public filteredItemList = {
    "items": [],
    "callBack": () => {
      this.filterItems();
    }
  };
  NumberPattern = "^[0-9]*$";
  MobilePattern = "^((\\+91-?)|0)?[0-9]{10}$";
  // MobilePattern = "/^(\+\d{1,3}[- ]?)?\d{10}$/";

  submitted = false;
  private gridApi: any;

  //myname: string = "asd";

  initGrid() {
    debugger;
    this.gridApi = _$('datatableNewLead');
    this.gridApi.initGridView(this.columnList, [], {});
    this.gridApi.dataBind([]);
    //this.gridApi.addNewRow({});
    //this.gridApi.setFocusCellLastRow(1);
    this.gridApi.addListener((e) => {

    })
  }
  NewLeadId: number = 0;
  readonlyStatus: boolean = false;
  ismobile: boolean = false;
  isEmail: boolean = false;
  UserId1: number;
  Isfashion: boolean = false;
  Issports: boolean = false;
  Issupermarket: boolean = false;
  Isglass: boolean = false;
  Isfurniture: boolean = false;
  Isfruit: boolean = false;
  Iselectronics: boolean = false;
  Isbeauty: boolean = false;
  Isapperal: boolean = false;
  Isaccessories: boolean = false;
  Isretail: boolean = false;
  Ishome: boolean = false;
  Isquickrestro: boolean = false;
  Ismeat: boolean = false;
  Isdelivery: boolean = false;
  Isbakery: boolean = false;
  Isspecial: boolean = false;
  Ispackage: boolean = false;
  Islubricant: boolean = false;
  Isbars: boolean = false;
  IsProductDelivery: boolean = false;
  PageName: any;
  ngOnInit() {

    debugger;
    //this.ngOnDestroy();
    //this.NewLead.RefreshEnvet();
    //this.test.Istest = true;

    //  this.add(true);
    window.scroll(0, 0);
    this.readonlyStatus = false;
    //this.Homepage.IsHomePage = false;
    this.NewLeadId = parseInt(localStorage.getItem('LeadId'));
    this.UserId = parseInt(localStorage.getItem('BranchId'));
    this.UserId1 = parseInt(localStorage.getItem('UserId'));
    this.UserParentId = parseInt(localStorage.getItem('ParentPartnerId'));
    this.RoleId = parseInt(localStorage.getItem('RoleId'));
    this.PageName = localStorage.getItem('PageName');
    if (this.PageName == 'Isfashion') {

      this.Isfashion = true
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false

    }
    else if (this.PageName == 'Issports') {
      this.Isfashion = false
      this.Issports = true
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Issupermarket') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = true
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isglass') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = true
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isfurniture') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = true
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isfruit') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = true
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Iselectronics') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = true
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isbeauty') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = true
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isapperal') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = true
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isaccessories') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = true
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isretail') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = true
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Ishome') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = true
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isquickrestro') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = true
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Ismeat') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = true
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isdelivery') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = true
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isbakery') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = true
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Isspecial') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = true
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Islubricant') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = true
      this.Islubricant = false
      this.Isbars = false
    }
    else if (this.PageName == 'Islubricant') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = true
      this.Isbars = false
    }
    else if (this.PageName == 'Isbars') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = true
    }
    else if (this.PageName == 'IsProductDelivery') {
      this.Isfashion = false
      this.Issports = false
      this.Issupermarket = false
      this.Isglass = false
      this.Isfurniture = false
      this.Isfruit = false
      this.Iselectronics = false
      this.Isbeauty = false
      this.Isapperal = false
      this.Isaccessories = false
      this.Isretail = false
      this.Ishome = false
      this.Isquickrestro = false
      this.Ismeat = false
      this.Isdelivery = false
      this.Isbakery = false
      this.Isspecial = false
      this.Ispackage = false
      this.Islubricant = false
      this.Isbars = false;
      this.IsProductDelivery = true;
    }



    console.log(this.UserId1);
    Globalcommonclass.GlobalBranchID = this.UserId;
    this.GetAssignToList();
    this.BusinessVerticalList();
    //console.log(this.UserId);
    this.DMLMode = "Insert";
    this.LeadForm = this.formBuilder.group({
      LeadId: [0],
      BussinessName: ['', Validators.required],
      AddressOne: [],
      // AddressTwo: [],
      Landmark: [],
      //Location: [],
      Pincode: [],
      Area: [],
      Taluka: [],
      District: [],
      Division: [],
      State: [],
      Zone: [],
      Country: [],
      Vertical: [],
      LeadSource: [],
      Activity: [],
      LeadStatus: [],
      Date: ['', Validators.required],
      NextPlan: [],
      GSTNo: [],
      AssignTo: [],
      RegistationType: [],
      SaleType: [],

      DefaultMobileNo: [],
      CustName: [],
      Cust_Designation: [],
      ContactType: [],
      ContactNo: ['', [Validators.maxLength(10), Validators.minLength(10)]],
      ContactEmail: [],
      ContactSkype: [],
      ContactBuss: [],
      ContactResidense: [],
      ContactWebsite: [],
      ContactWhatsapp: [],
      SoftWareVerticle: [],
      SoftWareEdition: [],
      NewLead_OTP: [],
      IsMtlstr: [],
      NewLead_CurrentStatus: [],
      LeadSource_Name: []

    });
    this.LeadForm.value.Date = new Date();

   

    //this.UserId = parseInt(localStorage.getItem('UserId'));
    this.UserId = parseInt(localStorage.getItem('UserId'));
    this.initGrid();

    localStorage.removeItem('Temp');

    localStorage.setItem('LeadId', '0');

    this.GetDataThroughUserId(this.UserId1);

    if (this.UserId1 == 1) {
      this.IsEditPriviledge = true;
    }

  }


  public BusinessVerticalList() {

    debugger;
    this.RequestdemoService.GetBusinessVerticalList().subscribe((data) => {
      debugger;
      this.businessverticals = data.data;
      console.log(this.businessverticals);

    })
  }

  public CreateNumber() {
    let RegPattern = new RegExp("^((\\+91-?)|0)?[0-9]{10}$");
    if (!RegPattern.test(this.LeadForm.value.ContactNo)) {
      alert("Mobile Number is not correct.");

    }
  }

  add(event) {
    debugger;
    if (event == true) {
      this.IsDefault = true;
    }
    else {
      this.IsDefault = false;
    }
    console.log(this.IsDefault);
  }
  Griddata = [];
  IsDefault: boolean = false;

  AddGridData() {
    debugger;
    this.submitted = true;
    if (this.LeadForm.value.ContactNo != "" && this.LeadForm.value.ContactNo != null) {
      let RegPattern = new RegExp("[6-9]\\d{9}");
      if (!RegPattern.test(this.LeadForm.value.ContactNo)) {
        return alert("Mobile Number is not correct.");
      }
    }
    if (this.LeadForm.value.ContactEmail != "" && this.LeadForm.value.ContactEmail != null) {
      var EMAIL_REGEXP = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
      if (!EMAIL_REGEXP.test(this.LeadForm.value.ContactEmail)) {
        return alert("Invalid Email Id.");
      }
    }
    debugger;
    console.log(this.IsDefault);
    if ((this.LeadForm.value.CustName != null) && (this.LeadForm.value.CustName != '')) {
      var cnt: number = 0
      var vnt: number = 0
      var result = this.gridApi.getData();
      result.forEach(q => {
        if (this.LeadForm.value.CustName == q.CustName && q.IsDefault == true) {
          cnt = 1;
        }
        else {
          if (this.LeadForm.value.CustName != q.CustName && q.IsDefault != true && this.IsDefault == true) {
            vnt = 1;
          }
        }
      });

      result.forEach(q => {
        if (vnt == 1 && q.IsDefault == true) {
          vnt = 0;
        }
      });

      if (result.length != 0) {
        if (cnt == 1) {
          this.IsDefault = true;
        }
        else if (vnt == 1) {
          this.IsDefault = true;
        }
        else {
          this.IsDefault = false;
        }

      }


      this.Griddata = [];
      let model: NewLeadDetails = new NewLeadDetails();

      if (this.LeadForm.value.ContactType == "Mobile Number") {

        model.ContactNo = this.LeadForm.value.ContactNo.toString();
        model.Cust_Designation = this.LeadForm.value.Cust_Designation;
        model.CustName = this.LeadForm.value.CustName;
        model.ContactType = this.LeadForm.value.ContactType;

      }
      else if (this.LeadForm.value.ContactType == "Email ID") {

        model.ContactNo = this.LeadForm.value.ContactEmail;
        model.Cust_Designation = this.LeadForm.value.Cust_Designation;
        model.CustName = this.LeadForm.value.CustName;
        model.ContactType = this.LeadForm.value.ContactType;
      }
      else if (this.LeadForm.value.ContactType == "Skype ID") {

        model.ContactNo = this.LeadForm.value.ContactSkype;
        model.Cust_Designation = this.LeadForm.value.Cust_Designation;
        model.CustName = this.LeadForm.value.CustName;
        model.ContactType = this.LeadForm.value.ContactType;
      }
      else if (this.LeadForm.value.ContactType == "Buss Phone") {

        model.ContactNo = this.LeadForm.value.ContactBuss;
        model.Cust_Designation = this.LeadForm.value.Cust_Designation;
        model.CustName = this.LeadForm.value.CustName;
        model.ContactType = this.LeadForm.value.ContactType;
      }
      else if (this.LeadForm.value.ContactType == "Residence") {

        model.ContactNo = this.LeadForm.value.ContactResidense;
        model.Cust_Designation = this.LeadForm.value.Cust_Designation;
        model.CustName = this.LeadForm.value.CustName;
        model.ContactType = this.LeadForm.value.ContactType;
      }
      else if (this.LeadForm.value.ContactType == "Website") {

        model.ContactNo = this.LeadForm.value.ContactWebsite;
        model.Cust_Designation = this.LeadForm.value.Cust_Designation;
        model.CustName = this.LeadForm.value.CustName;
        model.ContactType = this.LeadForm.value.ContactType;
      }
      else if (this.LeadForm.value.ContactType == "WhatsApp") {

        model.ContactNo = this.LeadForm.value.ContactWhatsapp;
        model.Cust_Designation = this.LeadForm.value.Cust_Designation;
        model.CustName = this.LeadForm.value.CustName;
        model.ContactType = this.LeadForm.value.ContactType;
      }
      model.IsDeleted = false;

      model.IsDefault = this.IsDefault;

      //if ($("#switch").prop("checked") == true) {

      //}
      var data = JSON.parse(localStorage.getItem('Temp'));
      //var data = JSON.parse(localStorage.getItem(''));
      if (data != null) {
        this.Griddata = data;

        this.Griddata.push(model);
        localStorage.setItem('Temp', JSON.stringify(this.Griddata));

        this.gridApi.clearGridData();
        this.gridApi.dataBind(this.Griddata);

      }
      else {
        this.Griddata.push(model);
        localStorage.setItem('Temp', JSON.stringify(this.Griddata));

        this.gridApi.clearGridData();
        this.gridApi.dataBind(this.Griddata);
      }
      var tData = this.gridApi.getData();
      tData.forEach(q => {
        if (q.IsDefault == true) {
          this.gridApi.HighLightRow(q);
        }

      });

      this.LeadForm.controls.Cust_Designation.setValue("");
      this.LeadForm.controls.CustName.setValue("");
      this.LeadForm.controls.ContactType.setValue("");
      this.LeadForm.controls.ContactWhatsapp.setValue("");
      this.LeadForm.controls.ContactWebsite.setValue("");
      this.LeadForm.controls.ContactResidense.setValue("");
      this.LeadForm.controls.ContactBuss.setValue("");
      this.LeadForm.controls.ContactSkype.setValue("");
      this.LeadForm.controls.ContactEmail.setValue("");
      this.LeadForm.controls.ContactNo.setValue("");

    }
    this.IsDefault = false;

  }
  mycontactfun() {

    $("select.myselect").change(function () {
      var selectedContactType = $(this).children("option:selected").val();
      // $(".mycontact").attr("placeholder", "Type " + selectedContactType);


      //$(".mycontact").css("display", "none");
      $(".ContactEmail").css("display", "none");
      $(".ContactSkype").css("display", "none");
      $(".ContactBuss").css("display", "none");
      $(".ContactResidense").css("display", "none");
      $(".ContactWebsite").css("display", "none");
      $(".ContactWhatsapp").css("display", "none");
      $(".ContactNo").css("display", "none");
      if (selectedContactType == "Mobile Number") {
        $(".ContactNo").css("display", "block");
        $(".ContactNo").val("");

      }
      if (selectedContactType == "Email ID") {
        $(".ContactEmail").css("display", "block");
        $(".ContactEmail").val("");
      }
      if (selectedContactType == "Skype ID") {
        $(".ContactSkype").css("display", "block");
      }
      if (selectedContactType == "Buss Phone") {
        $(".ContactBuss").css("display", "block");
      }
      if (selectedContactType == "Residence") {
        $(".ContactResidense").css("display", "block");
      }
      if (selectedContactType == "Website") {
        $(".ContactWebsite").css("display", "block");
      }
      if (selectedContactType == "WhatsApp") {
        $(".ContactWhatsapp").css("display", "block");
      }
      debugger;
      //if (1 == 1) {
      //  this.IsDefault = true;
      //}
      //var a = this.gridApi.getData();
      //if ((this.LeadForm.value.CustName == null) && (this.LeadForm.value.CustName == '')) {
      //  this.IsDefault = true;
      //}
      //if (a != null) {
      //  this.IsDefault = true;
      //}
    });
    this.IsDefault = true;
    var result = this.gridApi.getData();
    result.forEach(q => {
      if (this.LeadForm.value.ContactType == q.ContactType && this.DMLMode == 'Insert') {
        this.IsDefault = false;
      }
      else {
        this.IsDefault = true;
      }
    });
    //if ((this.LeadForm.value.ContactType == null) && (this.LeadForm.value.ContactType == ''))
    //var a = this.gridApi.getData();

    // if ((this.LeadForm.value.ContactType == null) && (this.LeadForm.value.ContactType == ''))
    //      {
    //        this.IsDefault = true;
    //      }
  }
  AssignList: any;
  public GetAssignToList() {
    debugger;
    this.RequestdemoService.GetRegisterationName(this.UserId).subscribe((response) => {
      debugger;
      if (!response.IsError) {
        this.AssignList = response.data;
      }
    });
  }






  private fullRowUpdate(value) {
    debugger;
    var row = this.gridApi.getCurrentRow();
    row.dataObj = value;
    row.dataObj["ContactNo"];
    row.dataObj["ContactType"];
    row.dataObj["Cust_Designation"];
    row.dataObj["objId"] = row.row.id;
    this.gridApi.updateRow(row.dataObj);
    this.gridApi.setFocusCellLastRow(1);
  }

  Pincode() {
    debugger;
    if (this.LeadForm.value.Pincode.errors == null) {
      this.RequestdemoService.GetDataThroughPincode(this.LeadForm.value.Pincode).subscribe(response => {
        if (response) {
          if (!response.IsError) {
            this.LeadForm.controls.Area.setValue(response.data.area);
            this.LeadForm.controls.Taluka.setValue(response.data.taluka);
            this.LeadForm.controls.District.setValue(response.data.districtName);
            this.LeadForm.controls.Division.setValue(response.data.stateDivisionName);
            this.LeadForm.controls.State.setValue(response.data.stateName);
            this.LeadForm.controls.Country.setValue(response.data.country);
            this.LeadForm.controls.Zone.setValue(response.data.countryName);
          }
          else {
            this.LeadForm.controls.Area.setValue("");
            this.LeadForm.controls.Taluka.setValue("");
            this.LeadForm.controls.District.setValue("");
            this.LeadForm.controls.Division.setValue("");
            this.LeadForm.controls.State.setValue("");
            this.LeadForm.controls.Country.setValue("");
          }
        }
      });
    }
    else {
      this.LeadForm.controls.Customershipping_State.setValue("");
    }

  }

  ButtonFunction(event) {

    if (event == "OK") {
      this.router.navigate(['./Home']);
    }
    else {
      this.HideExit();

    }
  }

  showExit() {
    $('#ExitModel').addClass('show');
    $('#ExitModel').addClass('in');
  }

  HideExit() {
    $('#ExitModel').removeClass('show');
    $('#ExitModel').removeClass('in');
  }

  //EditForm() {
  //  this.GetDataThroughUserId(this.UserId1);
  //  this.EditLeadModel.show();
  //  this.DMLMode = "Update";
  //}

  //ViewForm() {
  //  this.EditLeadModel.show();
  //  this.DMLMode = "View";
  //}
  LeadId: number = 0
  leadDetailsId: number = 0;

  ClearForm() {
    debugger
    this.LeadForm.reset();
    this.gridApi.clearGridData();
    localStorage.removeItem('Temp');
    this.add(true);
    this.DMLMode = "Insert"
  }

  Delete() {
    alert("Lead can not be deleted")
  }

  get L() { return this.LeadForm.controls; }
  VerticalId: string = "";
  Vertical_Id: number = 0;
  checkid : any = 1

  Submit() {
    debugger;
    //if (this.PartnerLead == 'Yes') {
    //  alert("Partner Lead Cannot Be Edited");
    //  this.PartnerLead = 'No';
    //  return;
    //}
    if (this.checkid == 1) {
      this.submitted = true;
      this.VerticalId = "";
      this.Vertical_Id = 0;

      if (this.checkid == 2) {

      }
      else {
        this.submitted = false;
        this.businessverticals.forEach((element, index) => {

          if (this.LeadForm.value.Vertical == element.businessVertical_Name) {
            this.VerticalId += element.businessvertical_id + ",";
          }

        });
        this.Vertical_Id = Number(this.VerticalId.slice(0, -1));
        if (this.LeadForm.value.RegistationType == "" || this.LeadForm.value.RegistationType == null) {
          this.LeadForm.value.RegistationType = "Unregister";
        }
        else {
          this.LeadForm.value.RegistationType = this.LeadForm.value.RegistationType.trim()
        }


        var assigndata = this.AssignList.filter(book => book.name === this.LeadForm.value.AssignTo)
        debugger;
        var data =
        {
          "NewLead": {
            "LeadId": Number(this.LeadForm.value.LeadId),
            "BussinessName": this.LeadForm.value.BussinessName,
            "AddressOne": "",
            "Landmark": "",
            "Pincode": this.LeadForm.value.Pincode,
            "Area": this.LeadForm.value.Area,
            "Taluka": this.LeadForm.value.Taluka,
            "District": this.LeadForm.value.District,
            "Division": this.LeadForm.value.Division,
            "State": this.LeadForm.value.State,
            "Zone": this.LeadForm.value.Zone,
            "Country": this.LeadForm.value.Country,
            "VerticalId": this.Vertical_Id,
            "Vertical": this.LeadForm.value.Vertical,
            "LeadSourceId": 0,
            "LeadSource": "Company Website",
            "LeadSource_Name": "",
            "ActivityId": 0,
            "Activity": "New",
            "LeadStatus": "New",
            "Date": Globalcommonclass.GlobalSystemDate,
            "NextPlanId": 0,
            "NextPlan": "New Lead",
            "AssignTo": "Retailsoft Technology",
            "AsLeadIncharge": true,
            "UserId": 1,
            "Name": "",
            "Role": "",
            "GSTNo": "",
            "RegistationType": "",
            "SaleType": "",
            "IsDeleted": false,
            "BusinessNo": this.LeadForm.value.BusinessNo,
            "Time": "",
            "DefaultMobileNo": (this.LeadForm.value.ContactNo).toString(),
            "DefaultEmailId": this.LeadForm.value.ContactEmail,
            "AssignToId": 1,
            "NewLead_CompanyId": Globalcommonclass.GlobalCompanyID,
            "NewLead_BranchId": this.UserId,
            "NewLead_PosId": Globalcommonclass.GlobalPOSID,
            "NewLead_BookType": Globalcommonclass.GlobalBookTypeID,
            "SoftWareVerticle": this.LeadForm.value.SoftWareVerticle,
            "SoftWareEdition": "POS",
            "NewLead_OTP": "",
            "NewLead_Desc": "CRM",
            "IsMtlstr": ((this.LeadForm.value.SoftWareVerticle == "Single Store") ? 0 : 1),
            "NewLead_CurrentStatus": "New Lead",
            "NewLead_ParentId": 1,
          },
          "LeadDetails": [
            {
             "LeadDetailsId" :0,
             "LeadId" : 0,
             "CustName"  : this.LeadForm.value.CustName,
             "Cust_Designation" : "",
             "ContactType"  : "Mobile Number",
             "ContactNo"  : (this.LeadForm.value.ContactNo).toString(),
             "CreatedBy" :1,
             "CreatedDate"  : Globalcommonclass.GlobalSystemDate,
             "ModifiedBy"   :1,
             "ModifiedDate"  : Globalcommonclass.GlobalSystemDate,
             "IsDeleted" : false,
             "IsDefault" : true
            }
          ]
        }

        
        var s = JSON.stringify(data);
        console.log('DATA:-', s)
        debugger;
        if (this.DMLMode == "Insert") {
          this.RequestdemoService.NewLeadAddOrUpdate(data).subscribe(response => {
            debugger;
            if (response.isError == false) {
              alert("Welcome To RetailSoft Technologies Pvt Ltd.For More Information Please Check Your MailId");
              this.SaveModalShow();
              this.LeadForm.reset();
              this.gridApi.clearGridData();
              this.DMLMode == "Insert"

            }
            else {
              //alert("Something went wrong please try again");
              alert(response.message);
              this.gridApi.clearGridData();

            }
          });
        }
        
        localStorage.removeItem('Temp');
      }
    }
  }


  public isLeadDateValid(): boolean {
    debugger;
    var tData = this.gridApi.getData();

    if (tData.length != 0) {
      this.ismobile = false;
      this.isEmail = false;

      tData.forEach(q => {
        if ("Mobile Number" == q.ContactType) {
          this.ismobile = true;
        }
        else {
          if ("Email ID" == q.ContactType) {
            this.isEmail = true;
          }
        }
      });

    }
    else {
      alert("Please Enter Default Contact");
      return false;
    }
    if ((this.LeadForm.value.BussinessName == "" || this.LeadForm.value.BussinessName == null || this.LeadForm.value.BussinessName == undefined)) {
      alert("Please Enter Bussiness Name");
      return false;
    }
    else if ((this.LeadForm.value.AddressOne == "" || this.LeadForm.value.AddressOne == null || this.LeadForm.value.AddressOne == undefined)) {
      alert("Please Enter Address");
      return false;
    }
    else if ((this.LeadForm.value.Landmark == "" || this.LeadForm.value.Landmark == null || this.LeadForm.value.Landmark == undefined)) {
      alert("Please Enter Landmark");
      return false;
    }
    else if ((this.LeadForm.value.Pincode == "" || this.LeadForm.value.Pincode == null || this.LeadForm.value.Pincode == undefined)) {
      alert("Please Enter Pincode");
      return false;
    }
    else if ((this.LeadForm.value.Vertical == "" || this.LeadForm.value.Vertical == null || this.LeadForm.value.Vertical == undefined)) {
      alert("Please Enter Vertical Name");
      return false;
    }

    else if ((this.LeadForm.value.LeadSource == "" || this.LeadForm.value.LeadSource == null || this.LeadForm.value.LeadSource == undefined)) {
      alert("Please Enter LeadSource");
      return false;
    }

    else if ((this.LeadForm.value.Activity == "" || this.LeadForm.value.Activity == null || this.LeadForm.value.Activity == undefined)) {
      alert("Please Select Activity");
      return false;
    }

    else if ((this.LeadForm.value.SoftWareVerticle == "" || this.LeadForm.value.SoftWareVerticle == null || this.LeadForm.value.SoftWareVerticle == undefined)) {
      alert("Please Enter SoftWareVerticle");
      return false;
    }

    else if ((this.LeadForm.value.SoftWareEdition == "" || this.LeadForm.value.SoftWareEdition == null || this.LeadForm.value.SoftWareEdition == undefined)) {
      alert("Please Enter SoftWareEdition");
      return false;
    }

    else if ((this.LeadForm.value.LeadStatus == "" || this.LeadForm.value.LeadStatus == null || this.LeadForm.value.LeadStatus == undefined)) {
      alert("Please Enter LeadStatus");
      return false;
    }
    else if (this.isEmail == false) {
      alert("Please Enter Mail Id");
      return false;
    }
    else if (this.ismobile == false) {
      alert("Please Enter Mobile No");
      return false;
    }
    else {
      return true;
    }

  }


  searchText: string;

  Company_GSTNo: string = "";
  Statecode: string = "";
  leadData: any;


  public CreatePanNumber() {
    debugger;
    let RegPattern = new RegExp("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$");
    if (!RegPattern.test(this.LeadForm.value.GSTNo)) {
      //this.LeadForm.value.RegistationType = 'Unregister';
      this.LeadForm.controls.RegistationType.setValue('Local');
      alert("GST No format is not correct.");

    }
    else {
      this.Statecode = this.LeadForm.value.GSTNo.substring(0, 2);
      if (this.Statecode == '27') {
        //this.LeadForm.value.SaleType = 'Local';
        this.LeadForm.controls.SaleType.setValue('Local');
      }
      else {
        //this.LeadForm.value.SaleType = 'Interstate'
        this.LeadForm.controls.SaleType.setValue('Interstate');
      }

      this.LeadForm.controls.RegistationType.setValue('Register');
    }

  }
  //Lead Get Data
  public GetDataThroughUserId(Id) {
    debugger;
    var data = {
      "UserId": this.UserId1,
      "Role": this.RoleId,
    }
    this.RequestdemoService.GetDataThroughUserId(data).subscribe((Response) => {
      debugger;
      this.leadData = Response.data;
      console.log(this.leadData);
      this.leadData.forEach((element) => {
        if (element.createdBy != 1) {
          this.PartnerLead = 'Yes';
        }
      });
    })
  }


  public selectedName: any;
  public HighLighLeadData(Lead) {
    this.selectedName = Lead.leadId;
    var row = document.getElementById('Lead-' + Lead.leadId);
    if (row) {
      //end,start,center,nearest
      row.scrollIntoView({ "block": "nearest", "behavior": "smooth" });
    }
  }

  public LeadModelKeyDown(ev) {
    debugger;
    switch (ev.keyCode) {
      //Down arrow
      case 40:

        if (this.filteredItemList['items'] && this.filteredItemList['items'].length) {
          if (ev.ctrlKey) {
            this.highlightedItem = this.filteredItemList['items'][this.filteredItemList['items'].length - 1];
            this.HighLighLeadData(this.highlightedItem);
          }
          else {
            if (!this.highlightedItem || Object.keys(this.highlightedItem).length == 0) {
              this.highlightedItem = this.filteredItemList['items'][0];
              this.HighLighLeadData(this.highlightedItem);
            }
            else {
              var index = this.filteredItemList['items'].indexOf(this.highlightedItem);
              if (index > -1 && index < (this.filteredItemList['items'].length - 1)) {
                this.highlightedItem = this.filteredItemList['items'][index + 1];
                this.HighLighLeadData(this.highlightedItem);
              }
            }
          }
        }
       
      
        break;
      //Up Arrow
      case 38:
        if (this.filteredItemList['items'] && this.filteredItemList['items'].length) {
          if (ev.ctrlKey) {
            this.highlightedItem = this.filteredItemList['items'][0];
            this.HighLighLeadData(this.highlightedItem);

          }
          else {
            if (!this.highlightedItem || Object.keys(this.highlightedItem).length == 0) {
              this.highlightedItem = this.highlightedItem = this.filteredItemList['items'][0];
              this.HighLighLeadData(this.highlightedItem);
            }
            else {
              var index = this.filteredItemList['items'].indexOf(this.highlightedItem);
              if (index > 0) {
                this.highlightedItem = this.filteredItemList['items'][index - 1];
                this.HighLighLeadData(this.highlightedItem);
              }
            }
          }
        }
        break;
      default:
        debugger;

        this.highlightedItem = this.filteredItemList['items'] && this.filteredItemList['items'].length ? this.filteredItemList['items'][0] : {};
        this.HighLighLeadData(this.highlightedItem);
    }
  }

  keyDownFunction(event) {
    if (event.keyCode == 38) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input, select"))
      const index =
        (inputs.indexOf(document.activeElement) - 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()
    }
    else if (event.keyCode == 13 || event.keyCode == 40) {
      event.preventDefault()
      const inputs =
        Array.prototype.slice.call(document.querySelectorAll("input, select"))
      const index =
        (inputs.indexOf(document.activeElement) + 1) % inputs.length
      const input = inputs[index]
      input.focus()
      input.select()


    }

  }

  filterItems() {
    this.highlightedItem = this.filteredItemList['items'] && this.filteredItemList['items'].length ? this.filteredItemList['items'][0] : {};
  }

  SaveModal(event) {
    debugger;
    if (event == "OK") {
      this.SaveModalhide();
    }
  }

  SaveModalShow() {

    $('#hidesavemodel').addClass('show');
    $('#hidesavemodel').addClass('in');
  }

  SaveModalhide() {

    $('#hidesavemodel').removeClass('show');
    $('#hidesavemodel').removeClass('in');
  }
  LeadSource: any;
  Source(event) {
    debugger;
    this.LeadSource = event;
    if (this.LeadSource == 'Friend Reffered' || this.LeadSource == 'Existing Customer' || this.LeadSource == 'Whatsapp Marketing') {
      this.IsSourceName = true;
    }
  }
}
