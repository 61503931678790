import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rt-accessories',
  templateUrl: './rt-accessories.component.html',
  styleUrls: ['./rt-accessories.component.css']
})
export class RtAccessoriesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Isaccessories');
  }
}
