import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rt-beautyncare',
  templateUrl: './rt-beautyncare.component.html',
  styleUrls: ['./rt-beautyncare.component.css']
})
export class RtBeautyncareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
  }
  Requestdemo() {
    localStorage.removeItem('PageName');
    localStorage.setItem('PageName', 'Isbeauty');
  }
}
