import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { BarsnbreweriesComponent } from './barsnbreweries/barsnbreweries.component';
import { ComplainprocedureComponent } from './complainprocedure/complainprocedure.component';
import { ContactusComponent } from './contactus/contactus.component';
import { DsLubricantsComponent } from './ds-lubricants/ds-lubricants.component';
import { DsPachagefoodComponent } from './ds-pachagefood/ds-pachagefood.component';
import { DsSpecializeddistributionComponent } from './ds-specializeddistribution/ds-specializeddistribution.component';
import { FbBakerynconfeComponent } from './fb-bakerynconfe/fb-bakerynconfe.component';
import { FbDelivertakewayComponent } from './fb-delivertakeway/fb-delivertakeway.component';
import { FbMeatnfrozenComponent } from './fb-meatnfrozen/fb-meatnfrozen.component';
import { FbQuickrestComponent } from './fb-quickrest/fb-quickrest.component';
import { HomeComponent } from './home/home.component';
import { LicenceaggrementComponent } from './licenceaggrement/licenceaggrement.component';
import { PartnerComponent } from './partner/partner.component';
import { PlanpricingComponent } from './planpricing/planpricing.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { ProductDeliveryComponent } from './product-delivery/product-delivery.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { RequestdemoComponent } from './requestdemo/requestdemo.component';
import { RetailComponent } from './retail/retail.component';
import { RtAccessoriesComponent } from './rt-accessories/rt-accessories.component';
import { RtApperatntextileComponent } from './rt-apperatntextile/rt-apperatntextile.component';
import { RtBeautyncareComponent } from './rt-beautyncare/rt-beautyncare.component';
import { RtElectronicsComponent } from './rt-electronics/rt-electronics.component';
import { RtFashionfootwareComponent } from './rt-fashionfootware/rt-fashionfootware.component';
import { RtFruitnveggiesComponent } from './rt-fruitnveggies/rt-fruitnveggies.component';
import { RtFurniturehomeComponent } from './rt-furniturehome/rt-furniturehome.component';
import { RtGlassncrockeriesComponent } from './rt-glassncrockeries/rt-glassncrockeries.component';
import { RtGroSupermarketComponent } from './rt-gro-supermarket/rt-gro-supermarket.component';
import { RtSportinggoodsComponent } from './rt-sportinggoods/rt-sportinggoods.component';
import { SupportEntitlementComponent } from './support-entitlement/support-entitlement.component';
import { SupportaggrementComponent } from './supportaggrement/supportaggrement.component';
import { SupportpricelistComponent } from './supportpricelist/supportpricelist.component';
import { SystemrequirementComponent } from './systemrequirement/systemrequirement.component';
import { TermoserviceComponent } from './termoservice/termoservice.component';



const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'aboutus', component: AboutUsComponent },
  { path: 'home', component: HomeComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'becomepartner', component: PartnerComponent },
  { path: 'home/solutions/retail', component: RetailComponent },
  { path: 'privacy-policy', component: PrivacypolicyComponent },
  { path: 'requestdemo', component: RequestdemoComponent },
  { path: 'home/solutions/retail/mobile&camera', component: RtAccessoriesComponent },
  { path: 'home/solutions/retail/electronics&eletrical', component: RtElectronicsComponent },
  { path: 'home/solutions/retail/groceries&supermarket', component: RtGroSupermarketComponent },
  { path: 'home/solutions/retail/fashion&footwearsolutions', component: RtFashionfootwareComponent },
  { path: 'home/solutions/retail/furniture&homedecorstoresolutions', component: RtFurniturehomeComponent },
  { path: 'home/solutions/retail/beauty&caresolutions', component: RtBeautyncareComponent },
  { path: 'home/solutions/retail/fruits&veggies', component: RtFruitnveggiesComponent },
  { path: 'home/solutions/retail/apperal&textiles', component: RtApperatntextileComponent },
  { path: 'home/solutions/retail/glass&crockeriesutensils', component: RtGlassncrockeriesComponent },
  { path: 'home/solutions/retail/sportinggoods', component: RtSportinggoodsComponent },
  { path: 'home/solutions/food&beverages/bakery&confectioneries', component: FbBakerynconfeComponent },
  { path: 'home/solutions/food&beverages/quickservicerestaurant', component: FbQuickrestComponent },
  { path: 'home/solutions/food&beverages/meat&frozenfoods', component: FbMeatnfrozenComponent },
  { path: 'home/solutions/food&beverages/deliveryandtakeways', component: FbDelivertakewayComponent },
  { path: 'home/solutions/food&beverages/bars&breweries', component: BarsnbreweriesComponent },
  { path: 'home/solutions/distribution/fmcg&packagedfoods', component: DsPachagefoodComponent },
  { path: 'home/solutions/distribution/specializeddistribution', component: DsSpecializeddistributionComponent },
  { path: 'home/solutions/distribution/lubricants', component: DsLubricantsComponent },
  { path: 'termsofservices', component: TermoserviceComponent },
  { path: 'end_of_licence_aggrement', component: LicenceaggrementComponent },
  { path: 'complainprocedure', component: ComplainprocedureComponent },
  { path: 'supportaggrement', component: SupportaggrementComponent },
  { path: 'Systemrequirement', component: SystemrequirementComponent },
  { path: 'planpricing', component: PlanpricingComponent },
  { path: 'supportpricelist', component: SupportpricelistComponent },
  { path: 'support_entitlement', component: SupportEntitlementComponent },
  { path: 'product_delivery', component: ProductDeliveryComponent },
  { path: 'refund-policy', component: RefundPolicyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
